import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../services/AxiosInstance";
import { useSelector } from "react-redux";
import "../packages.css";
//import { Link } from "react-router-dom";
// image
//import logo from "../../images/logo-full.png";
const  FeaturesPackages= ({ history }) => {
    const professional= useSelector((state)=>state.auth.auth)
    console.log(professional.professionalType,'users-->>>>>')
  const [packages, setPackage] = useState([]);
  const [coupon, setCoupon] = useState("");
  const Signup = (id) => {
    const formdata = new FormData();
    formdata.append("coupon", coupon);
    formdata.append("plan_id", id);
    formdata.append("professional", professional.localId);
    axiosInstance
      .post("professional/apply/feature", formdata)
      .then((e) => {
        if (e.data.status === true) {
          window.location.href = e.data.data;
         
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    axiosInstance
      .get("/plans")
      .then((e) => {
        console.log(e,'professional---->>>>>>>>');
        if (e.data.status === true) {
          setPackage(e.data.data);
        }
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <div className="authincation" style={{height:'100vh'}}>
      <div className="container ">
        <h2 className="text-center mt-3">
          Here are some <span className="title-colot"> Features Packsages</span>
        </h2>{" "}
        <div className="row mt-5">
          {packages
            .filter((e) => e.type === "Professional" && e.plan_type === "Feature" && e.professional_type === professional.professionalType)
            .map((e) => {
              return (
                
                <div className="col-md-6 col-sm-6 col-lg-4">
               
                  <div className="plan ">
                    <p className="plan-title">{e.title}</p>

                    <p className="plan-price">
                      {e.price}
                      <span>{e.duration}/Days</span>
                    </p>
                    <button className="btn1 mt-4" onClick={() => Signup(e.id)}>
                      Buy Now
                    </button>
                  </div>
              
                </div>
              );
            })}
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <input
                type="text"
                className="form-control mt-2"
                placeholder="Enter Coupon Code"
                onChange={(e) => setCoupon(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesPackages;
