function PlayerControl({ isPlaying, setIsPlaying, PlayingId, children }) {
  return (
    <div className="c-player--controls">
      <button className="play-btn z-1" onClick={() => setIsPlaying(PlayingId)}>
        {isPlaying ? (
          <i className="fa fa-pause" />
        ) : (
          <i className="fa fa-play" />
        )}
      </button>
      {children}
    </div>
  );
}

export default PlayerControl;
