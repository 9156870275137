import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "swiper/css";
import "swiper/css/effect-cards";
import { axiosInstance } from "../../services/AxiosInstance";
import PlayerIndex from "./Player";

const JudgesSong = () => {
  const { id } = useParams();
  const { event } = useParams();
  const [loader, setLoader] = useState(false);
  const [playingIndex, setPlayingIndex] = useState(null);
  const [data, setData] = useState([]);
  const [showPublish, setShowPublish] = useState(false);
  const [rank, setRank] = useState(null);
  const onshowPublish = () => setShowPublish(true);
  const onHidePublish = () => setShowPublish(false);

  const AssignSong = useCallback(() => {
    setLoader(true);
    axiosInstance
      .get(`judge/events/${id}/songs`)
      .then(function (response) {
        console.log(response, "Getting Companies");
        if (response?.data.status === true) {
          setData(response?.data.data);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
      });
  }, [id]);
  useEffect(() => {
    AssignSong();
  }, [AssignSong]);
  const isPlayedSong = (id) => {
    setLoader(true);
    try {
      axiosInstance
        .put(`judge/songs/mark_as_played_unplayed/${id}`)
        .then((response) => {
          console.log(response);
          if (response.data.status === true) {
            AssignSong();
            setRank(null);
            toast.success(response.data.response);
            setLoader(false);
          } else {
            setLoader(false);
            toast.error(response.data.error);
          }
        })
        .catch((error) => {
          setLoader(false);
          toast.success(error.response.data.erro);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };
  const selectSong = useCallback(() => {
    const formdata = new FormData();
    formdata.append("rank", rank?.rank);
    setLoader(true);
    try {
      axiosInstance
        .post(`judge/songs/mark_selected/${rank?.songId}`, formdata)
        .then((response) => {
          if (response.data.status === true) {
            AssignSong();
            setRank(null);
            toast.success(response.data.response);
            setLoader(false);
          } else {
            setLoader(false);
            toast.error(response.data.error);
          }
        })
        .catch((error) => {
          setLoader(false);
          toast.success(error.response.data.erro);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  }, [AssignSong, rank?.rank, rank?.songId]);
  useEffect(() => {
    if (rank !== null) selectSong();
  }, [rank, selectSong]);
  const Publish = () => {
    setLoader(true);
    try {
      axiosInstance
        .post(`judge/events/${id}/songs/selected/publish`)
        .then((response) => {
          console.log(response);
          if (response.data.status === true) {
            AssignSong();
            onHidePublish();
            toast.success(response.data.response);
            setLoader(false);
          } else {
            setLoader(false);
            toast.error(response.data.error);
          }
        })
        .catch((error) => {
          setLoader(false);
          toast.error(error.response.data.error);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };
  const unselectSong = (id) => {
    setLoader(true);
    try {
      axiosInstance
        .delete(`judge/songs/unmark_selected/${id}`)
        .then((response) => {
          console.log(response);
          if (response.data.status === true) {
            AssignSong();
            toast.success(response.data.response);
            setLoader(false);
          } else {
            setLoader(false);
            toast.error(response.data.error);
          }
        })
        .catch((error) => {
          setLoader(false);
          toast.success(error.response.data.erro);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };
  const MaybeSong = (id) => {
    setLoader(true);
    try {
      axiosInstance
        .put(`judge/songs/mark_unmark_as_maybe/${id}`)
        .then((response) => {
          console.log(response);
          if (response.data.status === true) {
            AssignSong();
            toast.success(response.data.response);
            setLoader(false);
          } else {
            setLoader(false);
            toast.error(response.data.error);
          }
        })
        .catch((error) => {
          setLoader(false);
          toast.success(error.response.data.erro);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <div className="container">
        <div className="row">
          <div className="float-right">
            <div
              className="btn btn-primary float-end mb-3"
              onClick={onshowPublish}
            >
              Publish{" "}
            </div>
          </div>
        </div>
        <div className="row">
          {/* Idher 2 map isliye chale he kiyu ke data zida he or sorting if esle pe real time pe work nhi kar rahi thi */}
          {data
            ?.filter((item) => item?.selected_song !== null)
            .sort((a, b) => {
              const RankA = a?.selected_song?.rank;
              const RankB = b?.selected_song?.rank;
              return RankB - RankA;
            })
            .map((e, index) => {
              return (
                <div className="col-md-12 col-sm-12" key={e.id}>
                  <div className="position-relative mb-3">
                    <PlayerIndex
                      img={e.thumbnail}
                      name={e.title}
                      genre={e.genre.name}
                      event={event}
                      audio={e.song}
                      PlayingId={index}
                      isPlaying={index === playingIndex}
                      updatePlayingIndex={(i) =>
                        setPlayingIndex(i === playingIndex ? null : i)
                      }
                      setRank={e?.selected_song?.rank}
                      is_published={e?.selected_song?.is_published}
                      islistened={e?.pivot?.is_played}
                      songId={e.pivot.id}
                      maybe={e.pivot.maybe}
                      selectedId={e?.selected_song?.id}
                      unselectSong={unselectSong}
                      MaybeSong={MaybeSong}
                      isPlayedSong={isPlayedSong}
                      isRanking={true}
                      getrank={setRank}
                    />
                  </div>
                </div>
              );
            })}
          {data
            ?.filter((item) => item?.selected_song === null)
            .sort((a, b) => {
              const RankA = a?.selected_song?.rank;
              const RankB = b?.selected_song?.rank;
              return RankB - RankA;
            })
            .map((e, index) => {
              return (
                <div className="col-md-12 col-sm-12" key={e.id}>
                  <div className="position-relative mb-3">
                    {console.log(e,"selected_song")}
                    <PlayerIndex
                      img={e.thumbnail}
                      name={e.title}
                      genre={e.genre.name}
                      event={event}
                      audio={e.song}
                      PlayingId={index}
                      isPlaying={index === playingIndex}
                      updatePlayingIndex={(i) =>
                        setPlayingIndex(i === playingIndex ? null : i)
                      }
                      setRank={e?.selected_song?.rank}
                      is_published={e?.selected_song?.is_published}
                      islistened={e?.pivot?.is_played}
                      songId={e.pivot.id}
                      maybe={e.pivot.maybe}
                      selectedId={e?.selected_song?.id}
                      judge_song_id={e?.selected_song?.judge_song_id}
                      unselectSong={unselectSong}
                      MaybeSong={MaybeSong}
                      isPlayedSong={isPlayedSong}
                      isRanking={true}
                      getrank={setRank}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <Modal show={showPublish} onHide={onHidePublish} size="md">
        <Modal.Header closeButton>
          <div className="text-center">
            <p className="text-center fs-3 fw-light">Disclaimer</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <h3 className="text-center">
              Please be aware that once a song has been published, it cannot be
              edited or modified.!!!
            </h3>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={Publish}>
            Publish it
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default JudgesSong;
