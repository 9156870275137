/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/alt-text */

function PlayerDetail({ song }) {
  console.log(song,"song");
  return (
    <div className="c-player--details">
      {/* <div className="details-img">
        <img
          src={song.img}
          style={{ width: 100, height: 100 }}
          atl={song.name}
        />
      </div> */}
      <h3 className="details-title">{song.name}</h3>
      <h4 className="details-artist">{song.genre}</h4>
    </div>
  );
}
export default PlayerDetail;
