/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import "react-calendar/dist/Calendar.css";

///Images

//Import
import { ThemeContext } from "../../../context/ThemeContext";
import RadialDount from "./Dashboard/RadialDount";

const Home = () => {
  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-xl-3 col-sm-6">
          <div className="card gradient-1 card-bx">
            <div className="card-body d-flex align-items-center">
              <div className="me-auto text-white">
                <h2 className="text-white">872</h2>
                <span className="fs-18">Judge</span>
              </div>
              <img src="/img/judge.png" width={"45%"} alt="" />
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6">
          <div className="card gradient-2 card-bx">
            <div className="card-body d-flex align-items-center">
              <div className="me-auto text-white">
                <h2 className="text-white">285</h2>
                <span className="fs-18">POP</span>
              </div>
              <img src="/img/mic.png" width={"45%"} alt="" />
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6">
          <div className="card gradient-3 card-bx">
            <div className="card-body d-flex align-items-center">
              <div className="me-auto text-white">
                <h2 className="text-white">53</h2>
                <span className="fs-18">HIPHOP</span>
              </div>
              <img src="/img/hiphop.png" width={"45%"} alt="" />
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6">
          <div className="card gradient-4 card-bx">
            <div className="card-body d-flex align-items-center">
              <div className="me-auto text-white">
                <h2 className="text-white">78</h2>
                <span className="fs-18">ROCK</span>
              </div>
              <img src="/img/rock.png" width={"45%"} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-3 col-xxl-4">
          <div className="row">
            <div className="col-xl-12 col-sm-6">
              <div className="card text-center">
                <div className="card-body">
                  <div id="radialChart" className="radialChart">
                    <RadialDount />
                  </div>
                  <h2>785</h2>
                  <span className="fs-16 text-black">Total Pop Vote</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-4">
          <div className="row">
            <div className="col-xl-12 col-sm-6">
              <div className="card text-center">
                <div className="card-body">
                  <div id="radialChart" className="radialChart">
                    <RadialDount />
                  </div>
                  <h2>285</h2>
                  <span className="fs-16 text-black">Total Rock Vote</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-4">
          <div className="row">
            <div className="col-xl-12 col-sm-6">
              <div className="card text-center">
                <div className="card-body">
                  <div id="radialChart" className="radialChart">
                    <RadialDount />
                  </div>
                  <h2>285</h2>
                  <span className="fs-16 text-black">Total HipHop Vote</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
