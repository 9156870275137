/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";

function SongProgressBar({ audioEl }) {
  const progressBarRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState("0:00");
  const [duration, setDuration] = useState("0:00");

  const updateProgressBar = () => {
    const currentTime = audioEl.current.currentTime;
    const duration = audioEl.current.duration;

    if (duration > 0) {
      const calculatedProgress = (currentTime / duration) * 100;
      setProgress(calculatedProgress);

      // Format current time and duration in "M:SS" format
      setCurrentTime(formatTime(currentTime));
      setDuration(formatTime(duration));
    }
  };

  useEffect(() => {
    audioEl.current.addEventListener("timeupdate", updateProgressBar);

    return () => {
      audioEl?.current?.removeEventListener("timeupdate", updateProgressBar);
    };
  }, [audioEl]);

  const handleProgressBarClick = (e) => {
    const progressBar = progressBarRef.current;
    const progressWidth = progressBar.clientWidth;
    const clickX = e.nativeEvent.offsetX;
    const duration = audioEl.current.duration;

    if (progressBar && duration) {
      const newTime = (clickX / progressWidth) * duration;
      audioEl.current.currentTime = newTime;
    }
    console.log(clickX);
  };

  // Function to format time in "M:SS" format
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    const formattedTime = `${minutes}:${String(seconds).padStart(2, "0")}`;
    return formattedTime;
  };
  return (
    <>
      <div
        className="song-progress-bar rounded-2"
        style={{ background: "#00000066" }}
        ref={progressBarRef}
        onClick={handleProgressBarClick}
      >
        <div
          className="progress-bar text-center"
          sty
          style={{
            width: `${progress}%`,
            height: 10,
            boxShadow: "white 0px 0px 2px 3px inset",
          }}
        >
          {" "}
        </div>
      </div>
      <div className="time-display text-end mt-1">
        {currentTime} / {duration}
      </div>
    </>
  );
}

export default SongProgressBar;
