import { RangeDatePicker } from "@y0c/react-datepicker";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import Dropzone from "react-dropzone-uploader";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Input, Label } from "reactstrap";
import { Autoplay, EffectCube, Pagination } from "swiper";
import "swiper/css/effect-cube";
import { Swiper, SwiperSlide } from "swiper/react";
import { axiosInstance } from "../../services/AxiosInstance";

const Event = () => {
  const { Eid } = useParams();
  const addquery = document.querySelector(".dzu-inputLabelWithFiles");
  if (addquery) {
    addquery.classList.add("btn", "btn-outline-primary");
  } else {
  }
  const singerData = () => {
    const initialData = [];
    for (let rank = 1; rank <= 9; rank++) {
      initialData.push({ rank, percent: "" });
    }
    return initialData;
  };
  const singerCap = () => {
    const initialData = [];
    for (let rank = 1; rank <= 3; rank++) {
      initialData.push({ rank, percent: "" });
    }
    return initialData;
  };
  const artistData = () => {
    const initialData = [];
    for (let rank = 1; rank <= 9; rank++) {
      initialData.push({ rank, percent: "" });
    }
    return initialData;
  };
  const [basicModal, setBasicModal] = useState(false);
  const [assign, setAssgin] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [getEvent, setgetEvent] = useState([]);
  const [judges, setJudges] = useState([]);
  const [viewEvent, setviewEvent] = useState({});
  const [title, setTitle] = useState("");
  const [song_upload_fees, setSong_upload_fees] = useState("");
  const [singer_cap_amount, setSinger_cap_amount] = useState(singerCap());
  const [judge_cap_amount, setJudge_cap_amount] = useState("");
  const [singer_voting_pool_percent, setSinger_voting_pool_percent] = useState(
    singerData()
  );
  const [singer_upload_percent, setSinger_upload_percent] = useState(
    artistData()
  );
  const [eventStartDate, setEventStartDate] = useState(null);
  const [eventEndDate, setEventEndDate] = useState(null);
  const [singerStartDate, setSingerStartDate] = useState(null);
  const [singerEndDate, setSingerEndDate] = useState(null);
  const [JudgeStartDate, setJudgeStartDate] = useState(null);
  const [JudgeEndDate, setJudgeEndDate] = useState(null);
  const [VoterStartDate, setVoterStartDate] = useState(null);
  const [VoterEndDate, setVoterEndDate] = useState(null);
  const [uploadedImages, setUploadedImages] = useState("");
  const [selectedJudges, setSelectedJudges] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [, setAllJudges] = useState([...judges]);
  // const [currentDate, setCurrentDate] = useState(new Date());

  const handleEventDate = (startDate, endDate) => {
    setEventStartDate(startDate);
    setEventEndDate(endDate);
  };
  const handleSingerDate = (startDate, endDate) => {
    setSingerStartDate(startDate);
    setSingerEndDate(endDate);
  };
  const handleJudgeDate = (startDate, endDate) => {
    setJudgeStartDate(startDate);
    setJudgeEndDate(endDate);
  };
  const handleVoterDate = (startDate, endDate) => {
    setVoterStartDate(startDate);
    setVoterEndDate(endDate);
  };
  const handleChangeStatus = ({ meta, remove }, status, file) => {
    if (status === "done") {
      setUploadedImages((prevImages) => [...prevImages, file]);
    }
    if (status === "removed") {
      toast.success("removed");
      setUploadedImages((prevImages) =>
        prevImages.filter((image) => image.id !== meta.id)
      );
    }
  };
  const handleSingerChange = (index, field, value) => {
    const updatedData = [...singer_voting_pool_percent];
    updatedData[index][field] = value;
    setSinger_voting_pool_percent(updatedData);
  };
  const handleSingerCap = (index, field, value) => {
    const updatedData = [...singer_cap_amount];
    updatedData[index][field] = value;
    setSinger_cap_amount(updatedData);
  };
  const handleArtistChange = (index, field, value) => {
    const updatedData = [...singer_upload_percent];
    updatedData[index][field] = value;
    setSinger_upload_percent(updatedData);
  };
  const handleSelectJudge = (judge) => {
    setSelectedJudges((prevSelectedJudges) => [...prevSelectedJudges, judge]);
    setAllJudges((prevAllJudges) =>
      prevAllJudges.filter((j) => j.id !== judge.id)
    );
  };
  const handleRemoveJudge = (judge) => {
    setSelectedJudges((prevSelectedJudges) =>
      prevSelectedJudges.filter((j) => j.id !== judge.id)
    );
    setAllJudges((prevAllJudges) => [...prevAllJudges, judge]);
  };
  const ViewEvent = (id) => {
    setLoader(true);
    try {
      axiosInstance
        .get(`admin/events/${id}`)
        .then((response) => {
          if (response.data.status === true) {
            setviewEvent(response.data.data);
            setSelectedJudges(response?.data?.data?.judges);
            setEditModal(true);
            setLoader(false);
          } else {
            setLoader(false);
          }
        })
        .catch((errors) => {
          setLoader(false);
        });
    } catch (error) {
      toast.error(error);
    }
  };
  const Event = () => {
    setLoader(true);
    try {
      axiosInstance
        .get(`admin/events`)

        .then((response) => {
          console.log(response);
          if (response.data.status === true) {
            setgetEvent(response?.data?.data?.data);
            setLoader(false);
          } else {
            setLoader(false);
          }
        })
        .catch((errors) => {
          setLoader(false);
        });
    } catch (error) {
      toast.error(error);
    }
  };
  const AddEvent = (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const formdata = new FormData();
      formdata.append("title", title);
      formdata.append(
        "event_start_date",
        eventStartDate
          ? eventStartDate.toISOString().slice(0, 10)
          : eventStartDate
      );
      formdata.append(
        "event_end_date",
        eventEndDate ? eventEndDate.toISOString().slice(0, 10) : eventEndDate
      );
      formdata.append(
        "singer_start_date",
        singerStartDate
          ? singerStartDate.toISOString().slice(0, 10)
          : singerStartDate
      );
      formdata.append(
        "singer_end_date",
        singerEndDate ? singerEndDate.toISOString().slice(0, 10) : singerEndDate
      );
      formdata.append(
        "judge_start_date",
        JudgeStartDate
          ? JudgeStartDate.toISOString().slice(0, 10)
          : JudgeStartDate
      );
      formdata.append(
        "judge_end_date",
        JudgeEndDate ? JudgeEndDate.toISOString().slice(0, 10) : JudgeEndDate
      );
      formdata.append(
        "voter_start_date",
        VoterStartDate
          ? VoterStartDate.toISOString().slice(0, 10)
          : VoterStartDate
      );
      formdata.append(
        "voter_end_date",
        VoterEndDate ? VoterEndDate.toISOString().slice(0, 10) : VoterEndDate
      );
      uploadedImages
        .flat(1)
        .map((e, i) => formdata.append(`images[${i}]`, e.file));
      formdata.append("song_upload_fees", song_upload_fees);
      formdata.append("singer_cap_amount", JSON.stringify(singer_cap_amount));
      formdata.append("judge_cap_amount", judge_cap_amount);
      formdata.append(
        "singer_voting_pool_percent",
        JSON.stringify(singer_voting_pool_percent)
      );
      formdata.append(
        "singer_upload_percent",
        JSON.stringify(singer_upload_percent)
      );
      axiosInstance
        .post("admin/events", formdata)
        .then((response) => {
          if (response.data.status === true) {
            setBasicModal(false);
            Event();
            const myForm = document.getElementById("myForm");
            myForm.reset();
            toast.success(response.data.response);
            setUploadedImages([]);
            setLoader(false);
          } else {
            toast.error(response.data.error);
            setLoader(false);
          }
        })
        .catch((errors) => {
          console.log(errors, "errors");
          setLoader(false);
        });
    } catch (error) {
      console.log(error, "error");
      toast.error(error);
    }
  };
  const DeleteEvent = (id) => {
    setLoader(true);
    try {
      axiosInstance
        .delete(`admin/events/${id}`)
        .then((response) => {
          if (response.data === 1) {
            Event();
            toast.success("Deleted");
            console.log(response);
            setEditModal(false);
            setLoader(false);
          } else {
            toast.error("SomeThing went Wrong");
            setLoader(false);
          }
        })
        .catch((errors) => {
          toast.error("SomeThing went Wrong");
          setLoader(false);
        });
    } catch (error) {
      toast.error(error);
    }
  };
  const DeleteEventImg = (id) => {
    setLoader(true);
    try {
      axiosInstance
        .delete(`admin/events/delete_image/${id}`)
        .then((response) => {
          if (response.data.status === true) {
            ViewEvent(Eid);
            toast.success("Deleted");
            console.log(response);
            setLoader(false);
          } else {
            toast.error("SomeThing went Wrong");
            setLoader(false);
          }
        })
        .catch((errors) => {
          toast.error("SomeThing went Wrong");
          setLoader(false);
        });
    } catch (error) {
      toast.error(error);
    }
  };
  const UpdateEventImg = (id) => {
    setLoader(true);
    const formdata = new FormData();
    uploadedImages
      .flat(1)
      .map((e, i) => formdata.append(`images[${i}]`, e.file));

    try {
      axiosInstance
        .post(`admin/events/update_images/${id}`, formdata)
        .then((response) => {
          if (response.data.status === true) {
            ViewEvent(Eid);
            toast.success(response.data.response);
            setUploadedImages([]);
            setEditModal(false);
            setLoader(false);
          } else {
            toast.error("SomeThing went Wrong");
            setLoader(false);
          }
        })
        .catch((errors) => {
          toast.error("SomeThing went Wrong");
          setLoader(false);
        });
    } catch (error) {
      toast.error(error);
    }
  };
  const Judges = () => {
    setLoader(true);
    try {
      axiosInstance
        .get(`admin/judges`)
        .then((response) => {
          if (response.data.status === true) {
            setJudges(response.data.data);
            console.log(response);
            setLoader(false);
          } else {
            setLoader(false);
          }
        })
        .catch((errors) => {
          setLoader(false);
        });
    } catch (error) {
      toast.error(error);
    }
  };
  const De_AttachJudges = () => {
    setLoader(true);
    const formdata = new FormData();
    selectedJudges.map((e, i) =>
      formdata.append(`judges[${i}][number]`, i + 1)
    );
    selectedJudges.map((e, i) => formdata.append(`judges[${i}][id]`, e.id));
    try {
      axiosInstance
        .post(`admin/events/${Eid}/attach-judges`, formdata)
        .then((response) => {
          if (response.data.status === true) {
            ViewEvent(Eid);
            setEditModal(false);
            setAssgin(false);
            console.log(response);
            toast.success(response.data.message);
            setLoader(false);
          } else {
            setLoader(false);
          }
        })
        .catch((errors) => {
          setLoader(false);
        });
    } catch (error) {
      toast.error(error);
    }
  };
  useEffect(() => {
    Event();
  }, []);
  // const getTimeRemaining = (endDate) => {
  //   const totalMilliseconds = new Date(endDate) - currentDate;
  //   const days = Math.floor(totalMilliseconds / (1000 * 60 * 60 * 24));
  //   const hours = Math.floor(
  //     (totalMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  //   );
  //   const minutes = Math.floor(
  //     (totalMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
  //   );
  //   const seconds = Math.floor((totalMilliseconds % (1000 * 60)) / 1000);

  //   return {
  //     days,
  //     hours,
  //     minutes,
  //     seconds,
  //   };
  // };
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentDate(new Date());
  //   }, 1000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);
  // const EventPhase = ({ phase, start, end, label }) => {
  //   const currentTime = new Date();
  //   const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

  //   const getTimeRemainingString = (date) => {
  //     const timeRemaining = getTimeRemaining(date); // Assuming this function calculates the time remaining
  //     return `${timeRemaining.days} days, ${timeRemaining.hours} hours, ${timeRemaining.minutes} minutes, ${timeRemaining.seconds} seconds`;
  //   };

  //   if (label === "Event" && phase === "Event" && currentTime < start) {
  //     return (
  //       <p>
  //         {label} Start in: {getTimeRemainingString(start)}
  //       </p>
  //     );
  //   } else if (currentTime <= end) {
  //     return (
  //       <p>
  //         {label} Ends in: {getTimeRemainingString(end)}
  //       </p>
  //     );
  //   } else if (
  //     currentTime > end &&
  //     currentTime <= new Date(end.getTime() + oneDayInMilliseconds)
  //   ) {
  //     return <p>{label} date has ended</p>;
  //   } else {
  //     return null; // Event has passed and it's more than a day after the end date
  //   }
  // };

  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <div className="conatiner">
        <div className="row">
          <div className="col-md-12">
            <div className="row mb-3">
              <div className="col-md-8">
                <h3>Events</h3>
              </div>
              <div className="col-md-4">
                <button
                  className="btn float-end btn-outline-primary"
                  onClick={() => setBasicModal(true)}
                >
                  Add New Event
                </button>
              </div>
            </div>
            <div className="row">
              <div class="col-lg-4 col-sm-12 my-3">
                <input
                  type="search"
                  placeholder="Search..."
                  className="form-control"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-3">
                <div className="">
                  {getEvent &&
                    (getEvent?.filter((e) =>
                      e.title.toLowerCase().includes(searchQuery.toLowerCase())
                    ).length === 0 ? (
                      <p>No Event Found</p>
                    ) : (
                      getEvent
                        .filter((e) =>
                          e.title
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase())
                        )
                        .map((e) => {
                          return (
                            <>
                              {" "}
                              <Swiper
                                effect={"cube"}
                                grabCursor={true}
                                cubeEffect={{
                                  shadow: true,
                                  slideShadows: true,
                                  shadowOffset: 20,
                                  shadowScale: 0.94,
                                }}
                                spaceBetween={30}
                                pagination={{ clickable: true }}
                                modules={[Autoplay, EffectCube, Pagination]}
                                autoplay={true}
                                loop={true}
                                className="mySwiper3"
                              >
                                {" "}
                                <div className="card shadow-lg p-3">
                                  {" "}
                                  {e.images &&
                                    e.images.map((x, index) => (
                                      <>
                                        <SwiperSlide
                                          key={index}
                                          className="card shadow-lg mt-5 mb-5 p-3"
                                        >
                                          {" "}
                                          <Link
                                            to={`/Event/${e.id}`}
                                            onClick={() => ViewEvent(e.id)}
                                          >
                                            {" "}
                                            <div className="position-relative">
                                              {" "}
                                              <img
                                                src={x.image}
                                                className="w-100"
                                                height={300}
                                                alt=""
                                              />{" "}
                                              <div className="image-overlay"></div>{" "}
                                              <div
                                                className="position-absolute text-center  w-100 z-1"
                                                style={{ top: "3%" }}
                                              >
                                                {" "}
                                                <h1 className="text-center text-white my-custom-font">
                                                  {" "}
                                                  {e.title}{" "}
                                                </h1>{" "}
                                              </div>{" "}
                                              {/* <div className="text-white text-center bg-dark">
                                                <EventPhase
                                                  phase="Event"
                                                  start={
                                                    new Date(e.event_start_date)
                                                  }
                                                  end={
                                                    new Date(e.event_end_date)
                                                  }
                                                  label="Event"
                                                />
                                                <EventPhase
                                                  phase="Singer"
                                                  start={
                                                    new Date(
                                                      e.singer_start_date
                                                    )
                                                  }
                                                  end={
                                                    new Date(e.singer_end_date)
                                                  }
                                                  label="Singer"
                                                />
                                                <EventPhase
                                                  phase="Judge"
                                                  start={
                                                    new Date(e.judge_start_date)
                                                  }
                                                  end={
                                                    new Date(e.judge_end_date)
                                                  }
                                                  label="Judge"
                                                />
                                                <EventPhase
                                                  phase="Voter"
                                                  start={
                                                    new Date(e.voter_start_date)
                                                  }
                                                  end={
                                                    new Date(e.voter_end_date)
                                                  }
                                                  label="Voting"
                                                />
                                              </div> */}
                                            </div>{" "}
                                          </Link>{" "}
                                        </SwiperSlide>
                                        <br />
                                      </>
                                    ))}{" "}
                                </div>{" "}
                              </Swiper>{" "}
                            </>
                          );
                        })
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Add Modal */}
      <Modal className="fade" size="xl" show={basicModal}>
        <Form onSubmit={AddEvent}>
          <Modal.Header>
            <Modal.Title>Add New Event</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setBasicModal(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Col md={12}>
                <Row>
                  <Col md={12} className="mb-3">
                    <Label>Event Title</Label>
                    <Input
                      type="text"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Col>
                  <Col md={6} className="mb-3">
                    <Label>Event Start-End Date</Label>
                    <RangeDatePicker
                      startText="Start"
                      endText="End"
                      startPlaceholder="Start Date"
                      endPlaceholder="End Date"
                      showToday
                      includeTime
                      onChange={handleEventDate}
                      // Set the following props to make the picker only include date
                      // showTimeSelect={true} // This disables the time picker
                      dateFormat="YYYY-MM-DD" // Format to display the date
                      selectedStartDate={eventStartDate} // Pass the selected start date
                      selectedEndDate={eventEndDate} // Pass the selected end date
                    />
                  </Col>
                  <Col md={6} className="mb-3">
                    <Label>Singer Start-End Date</Label>
                    <RangeDatePicker
                      startText="Start"
                      endText="End"
                      startPlaceholder="Start Date"
                      endPlaceholder="End Date"
                      showToday
                      onChange={handleSingerDate}
                      // Set the following props to make the picker only include date
                      showTimeSelect={false} // This disables the time picker
                      dateFormat="YYYY-MM-DD" // Format to display the date
                      selectedStartDate={singerStartDate} // Pass the selected start date
                      selectedEndDate={singerEndDate} // Pass the selected end date
                    />
                  </Col>
                  <Col md={6} className="mb-3">
                    <Label>Judge Start-End Date</Label>
                    <RangeDatePicker
                      startText="Start"
                      endText="End"
                      startPlaceholder="Start Date"
                      endPlaceholder="End Date"
                      showToday
                      onChange={handleJudgeDate}
                      // Set the following props to make the picker only include date
                      showTimeSelect={false} // This disables the time picker
                      dateFormat="YYYY-MM-DD" // Format to display the date
                      selectedStartDate={JudgeStartDate} // Pass the selected start date
                      selectedEndDate={JudgeEndDate} // Pass the selected end date
                    />
                  </Col>
                  <Col md={6} className="mb-3">
                    <Label>Voting Start-End Date</Label>
                    <RangeDatePicker
                      startText="Start"
                      endText="End"
                      startPlaceholder="Start Date"
                      endPlaceholder="End Date"
                      showToday
                      onChange={handleVoterDate}
                      showTimeSelect={false}
                      dateFormat="YYYY-MM-DD" // Use lowercase format tokens
                      selectedStartDate={VoterStartDate}
                      selectedEndDate={VoterEndDate}
                    />
                  </Col>
                  <Col md={12} className="mb-3">
                    <Label>Banner Images</Label>
                    <Dropzone
                      onChangeStatus={handleChangeStatus}
                      accept="image/*" // Specify accepted file types (images in this case)
                    />
                  </Col>
                  <Col md={3} className="mb-3">
                    <Label>Song Upload Fees</Label>
                    <Input
                      type="number"
                      onChange={(e) => setSong_upload_fees(e.target.value)}
                    />
                  </Col>
                  <Col md={3} className="mb-3">
                    <Label>Judge Capped Amount</Label>
                    <Input
                      type="number"
                      onChange={(e) => setJudge_cap_amount(e.target.value)}
                    />
                  </Col>
                  <Col md={6} className="mb-3">
                    <Label>Singer Capped Amount</Label>
                    <Row>
                      {" "}
                      {singer_cap_amount.map((data, index) => (
                        <Col key={index} md={4} sm={4} xl={4} className="mb-3">
                          {/* <Label>Rank {data.rank}</Label> */}
                          <Input
                            type="number"
                            placeholder={`Rank ${data.rank}`}
                            value={data.percent}
                            onChange={(e) =>
                              handleSingerCap(index, "percent", e.target.value)
                            }
                          />
                        </Col>
                      ))}
                    </Row>
                  </Col>

                  <Col md={12} className="mb-3">
                    <Label>Singer Voting Pool Percent(%)</Label>
                    <Row>
                      {" "}
                      {singer_voting_pool_percent.map((data, index) => (
                        <Col key={index} md={2} sm={3} xl={2} className="mb-3">
                          <Label>Rank {data.rank}</Label>
                          <Input
                            type="number"
                            value={data.percent}
                            onChange={(e) =>
                              handleSingerChange(
                                index,
                                "percent",
                                e.target.value
                              )
                            }
                          />
                        </Col>
                      ))}
                    </Row>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Label>Artist Upload Percent(%)</Label>
                    <Row>
                      {singer_upload_percent.map((data, index) => (
                        <Col key={index} md={2} sm={3} xl={2} className="mb-3">
                          <Label>Rank {data.rank}</Label>
                          <Input
                            type="number"
                            value={data.percent}
                            onChange={(e) =>
                              handleArtistChange(
                                index,
                                "percent",
                                e.target.value
                              )
                            }
                          />
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              Save changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* View Modal */}
      <Modal className="fade" size="xl" show={editModal}>
        <Modal.Header>
          <Modal.Title>View {assign ? "Judges" : "Event"}</Modal.Title>

          <Button
            variant="outline-primary"
            onClick={() => {
              setAssgin(!assign);
              Judges();
            }}
          >
            {assign ? "Event" : "Judges"}
          </Button>
        </Modal.Header>
        <Modal.Body>
          {!assign ? (
            <Container>
              <Col md={12}>
                <Row>
                  <Col md={12} className="mb-3">
                    <Label>Event Title</Label>
                    <Input readOnly type="text" value={viewEvent?.title} />
                  </Col>
                  <Col md={6} className="mb-3">
                    <Label>Event Start-End Date</Label>
                    <Input
                      readOnly
                      type="text"
                      value={
                        viewEvent?.event_start_date +
                        " ↔ " +
                        viewEvent?.event_end_date
                      }
                    />
                  </Col>
                  <Col md={6} className="mb-3">
                    <Label>Singer Start-End Date</Label>
                    <Input
                      readOnly
                      type="text"
                      value={
                        viewEvent?.singer_start_date +
                        " ↔ " +
                        viewEvent?.singer_end_date
                      }
                    />
                  </Col>
                  <Col md={6} className="mb-3">
                    <Label>Judge Start-End Date</Label>
                    <Input
                      readOnly
                      type="text"
                      value={
                        viewEvent?.judge_start_date +
                        " ↔ " +
                        viewEvent?.judge_end_date
                      }
                    />
                  </Col>
                  <Col md={6} className="mb-3">
                    <Label>Voting Start-End Date</Label>
                    <Input
                      readOnly
                      type="text"
                      value={
                        viewEvent?.voter_start_date +
                        " ↔ " +
                        viewEvent?.voter_end_date
                      }
                    />
                  </Col>
                  <Col md={12} className="mb-3">
                    <Label>Update Banner Images</Label>
                    <Dropzone
                      onChangeStatus={handleChangeStatus}
                      accept="image/*" // Specify accepted file types (images in this case)
                    />{" "}
                    <Row>
                      {viewEvent &&
                        viewEvent?.images?.map((e, i) => {
                          return (
                            <>
                              <Col md={3} sm={3} xs={4}>
                                <div className="position-relative">
                                  <a
                                    href={e.image}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src={e.image}
                                      style={{ width: "100%", height: 100 }}
                                      alt=""
                                      className="my-3 img-thumbnail"
                                    />
                                  </a>
                                  <div
                                    className="position-absolute w-100 z-1"
                                    style={{ top: "3%", left: "100%" }}
                                  >
                                    <i
                                      className="fa fa-trash text-danger"
                                      onClick={() => DeleteEventImg(e.id)}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </>
                          );
                        })}
                    </Row>
                  </Col>
                  <Col md={3} className="mb-3">
                    <Label>Song Upload Fees</Label>
                    <Input
                      readOnly
                      type="number"
                      value={viewEvent?.song_upload_fees}
                    />
                  </Col>
                  <Col md={3} className="mb-3">
                    <Label>Judge Capped Amount</Label>
                    <Input
                      readOnly
                      type="number"
                      value={viewEvent?.judge_cap_amount}
                    />
                  </Col>
                  <Col md={6} className="mb-3">
                    <Label>Singer Capped Amount</Label>
                    <Row>
                      {" "}
                      {viewEvent?.singer_cap_amount &&
                        JSON.parse(viewEvent?.singer_cap_amount).map(
                          (data, index) => (
                            <Col
                              key={index}
                              md={4}
                              sm={4}
                              xl={4}
                              className="mb-3"
                            >
                              {/* <Label>Rank {data.rank}</Label> */}
                              <Input
                                readOnly
                                type="number"
                                placeholder={`Rank ${data.rank}`}
                                value={data.percent}
                              />
                            </Col>
                          )
                        )}
                    </Row>{" "}
                  </Col>
                  <Col md={12} className="mb-3">
                    <Label>Singer Voting Pool Percent(%)</Label>
                    <Row>
                      {" "}
                      {viewEvent?.singer_voting_pool_percent &&
                        JSON.parse(viewEvent?.singer_voting_pool_percent).map(
                          (data, index) => (
                            <Col
                              key={index}
                              md={2}
                              sm={3}
                              xl={2}
                              className="mb-3"
                            >
                              <Label>Rank {data.rank}</Label>
                              <Input
                                readOnly
                                type="number"
                                value={data.percent}
                              />
                            </Col>
                          )
                        )}
                    </Row>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Label>Artist Upload Percent(%)</Label>
                    <Row>
                      {viewEvent?.singer_upload_percent &&
                        JSON.parse(viewEvent?.singer_upload_percent).map(
                          (data, index) => (
                            <Col
                              key={index}
                              md={2}
                              sm={3}
                              xl={2}
                              className="mb-3"
                            >
                              <Label>Rank {data.rank}</Label>
                              <Input
                                readOnly
                                type="number"
                                value={data.percent}
                              />
                            </Col>
                          )
                        )}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Container>
          ) : (
            <Container>
              <Row>
                <Col md={6} className="border border-2">
                  <div className="my-2">
                    <h4 className="text-center">All Judges</h4>
                    <div>
                      {judges
                        .filter(
                          (x) =>
                            !selectedJudges.some(
                              (selectedJudge) => selectedJudge.id === x.id
                            )
                        )
                        .reduce((genreGroups, judge) => {
                          const genreId = judge.genre.id;
                          const existingGroup = genreGroups.find(
                            (group) => group.id === genreId
                          );

                          if (existingGroup) {
                            existingGroup.judges.push(judge);
                          } else {
                            genreGroups.push({ id: genreId, judges: [judge] });
                          }

                          return genreGroups;
                        }, [])
                        .map((group) => (
                          <div key={group.id}>
                            <h5>{group.judges[0]?.genre.name}</h5>
                            {group.judges.map((judge, index) => (
                              <div
                                key={judge.id}
                                className="judge-item selected"
                                onClick={() => handleSelectJudge(judge)}
                              >
                                <h5>
                                  {index + 1}:{judge.fname} {judge.lname} -{" "}
                                  {judge.genre.name}
                                </h5>
                              </div>
                            ))}
                          </div>
                        ))}
                    </div>
                  </div>
                </Col>
                <Col md={6} className="border border-2">
                  <div className="my-2">
                    <h4 className="text-center">Selected Judges</h4>
                    {selectedJudges
                      .reduce((genreGroups, judge) => {
                        const genreId = judge.genre.id;
                        const existingGroup = genreGroups.find(
                          (group) => group.id === genreId
                        );

                        if (existingGroup) {
                          existingGroup.judges.push(judge);
                        } else {
                          genreGroups.push({ id: genreId, judges: [judge] });
                        }

                        return genreGroups;
                      }, [])
                      .map((group) => (
                        <div key={group.id}>
                          <h5>{group.judges[0]?.genre.name}</h5>
                          {group.judges.map((judge, index) => (
                            <div
                              key={judge.id}
                              className="judge-item selected"
                              onClick={() => handleRemoveJudge(judge)}
                            >
                              <h5>
                                {index + 1}:{judge.fname} {judge.lname} -{" "}
                                {judge.genre.name}
                              </h5>
                            </div>
                          ))}
                        </div>
                      ))}
                  </div>
                </Col>
              </Row>
            </Container>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!assign ? (
            <>
              <Button
                variant="success"
                onClick={() => UpdateEventImg(viewEvent?.id)}
              >
                Update
              </Button>
              <Button
                variant="danger"
                onClick={() => DeleteEvent(viewEvent?.id)}
              >
                Delete
              </Button>
            </>
          ) : (
            <Button variant="success" onClick={De_AttachJudges}>
              Save
            </Button>
          )}

          <Button
            variant="primary"
            onClick={() => {
              setEditModal(false);
              setAssgin(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Event;
