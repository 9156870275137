import React from "react";
import ReactApexChart from "react-apexcharts";

const RadialDount = () => {
  const series = [50];
  const options = {
    chart: {
      type: "radialBar",
      height: 150,
      sparkline: {
        enabled: true,
      },
    },
    colors: ["var(--primary)"],
    plotOptions: {
      radialBar: {
        hollow: {
          size: "35%",
        },
        dataLabels: {
          show: false,
        },
      },
    },
    labels: [""],
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="radialBar"
        height={150}
      />
    </div>
  );
};

export default RadialDount;
